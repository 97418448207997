<template>
  <div class="big-box">
    <div class="head-box">
      <el-button type="primary" v-if="$has('add')" size="medium" @click="newAdd"
        >新建</el-button
      >
      <el-button
        type="danger"
        v-if="$has('del')"
        @click="delMolude"
        size="medium"
        >删除</el-button
      >
    </div>
    <div>
      <template>
        <el-table
          stripe
          :data="tableData"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="id" align="center" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            prop="code"
            align="center"
            label="模块编码"
            min-width="150"
          >
          </el-table-column>
          <el-table-column
            prop="title"
            align="center"
            min-width="180"
            label="模块名称"
          >
          </el-table-column>
          <el-table-column
            prop="type"
            align="center"
            min-width="120"
            label="模块类型"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.type === 1">轮播</span>
              <span v-if="scope.row.type === 2">单图</span>
              <!-- 1启用 2禁用 -->
            </template>
          </el-table-column>
          <el-table-column
            prop="status_cn"
            align="center"
            min-width="120"
            label="是否启用"
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status == 1" type="success">已启用</el-tag>
              <el-tag type="danger" v-else>已禁用</el-tag>
              <!-- 1启用 2禁用 -->
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            align="center"
            min-width="180"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                v-if="$has('edit')"
                @click="detailEdit(scope.row)"
                size="small"
                >详情编辑</el-button
              >
              <!-- 1启用 2禁用 -->
              <el-button
                type="danger"
                v-if="scope.row.status === 1 && $has('change')"
                @click="editStatus(2, scope.row)"
                size="small"
                >停用</el-button
              >
              <el-button
                type="primary"
                v-if="scope.row.status === 2 && $has('change')"
                @click="editStatus(1, scope.row)"
                size="small"
                >启用</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-left: 50px; margin-top: 10px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="10"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </template>
    </div>
    <el-dialog
      :visible.sync="dialogPvVisible"
      width="1050px"
      :title="title === '0' ? '新建模块配置' : '编辑模块'"
    >
      <el-form label-width="150px" :inline="true">
        <el-form-item required label="模块编号:">
          <el-input
            maxlength="20"
            placeholder="请输入模块编号"
            v-model="codes"
          ></el-input>
        </el-form-item>
        <el-form-item required label="模块名称:">
          <el-input placeholder="请输入模块名称" v-model="titles"></el-input>
        </el-form-item>
        <el-form-item required label="模块类型:">
          <el-select v-model="types" placeholder="--请选择-- ">
            <el-option label="单图活动Banner" :value="2"></el-option>
            <el-option label="轮播Banner" :value="1"></el-option>
          </el-select>
        </el-form-item>

        <div
          v-for="(item, index) in singleList"
          :key="index + 'imgList'"
          v-show="types == 2"
        >
          <el-form-item required :label="'图' + (index + 1) + ':'">
            <el-input
              oninput="value=value.replace(/^0|[^0-9]/g, '')"
              type="number"
              :placeholder="'请输入' + (index + 1)"
              v-model="item.image"
            ></el-input>
          </el-form-item>
          <el-form-item required label="跳转链接:">
            <el-input
              placeholder="请输入跳转链接"
              v-model="item.skip_url"
            ></el-input>
            <el-button
              style="margin-left: 10px; margin-right: 0px"
              size="small"
              type="primary"
              @click="checkImg(item)"
              >查看</el-button
            >
            <!-- style="margin-left: 10px; margin-right: 200px" -->
            <el-button size="small" type="danger" @click="delBanner(index)"
              >删除</el-button
            >
          </el-form-item>
          <!-- <el-form-item label="排序:">
            <el-input
              placeholder="请输入排序"
              oninput="value=value.replace(/^0|[^0-9]/g, '')"
              maxlength="2"
              v-model="item.sort"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="热门活动:">
            <el-select v-model="item.hot_activity" placeholder="--请选择-- ">
              <el-option label="展示" :value="1"></el-option>
              <el-option label="不展示" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文案引导:">
            <el-select
              v-model="item.guidance_document"
              placeholder="--请选择-- "
            >
              <el-option label="展示" :value="1"></el-option>
              <el-option label="不展示" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="活动文案一:">
            <el-input
              placeholder="请输入活动文案一"
              v-model="item.activity_document_one"
            ></el-input>
          </el-form-item>
          <el-form-item label="活动文案二:">
            <el-input
              placeholder="请输入活动文案二"
              v-model="item.activity_document_two"
            ></el-input>
          </el-form-item>
          <el-form-item label="参与人数:">
            <el-input
              placeholder="请输入参与人数"
              v-model="item.activity_people_number"
            ></el-input>
          </el-form-item>
          <div class="add-image">
            <span @click="addBanner(index)">增加活动Banner</span>
          </div>
          <el-divider></el-divider>
        </div>

        <div
          v-show="types == 1"
          v-for="(item, index) in imgList"
          :key="index + 'index'"
        >
          <el-form-item required :label="'图' + (index + 1) + ':'">
            <el-input
              :placeholder="'请输入图' + (index + 1)"
              oninput="value=value.replace(/^0|[^0-9]/g, '')"
              type="number"
              v-model="item.image"
            ></el-input>
          </el-form-item>
          <el-form-item label="跳转链接:">
            <el-input
              placeholder="请输入跳转链接"
              v-model="item.skip_url"
            ></el-input>
            <el-button
              style="margin-left: 10px"
              size="small"
              type="primary"
              @click="checkImg(item)"
              >查看</el-button
            >
            <el-button
              v-if="imgList.length > 1"
              style="margin-left: 10px"
              @click="delImg(index)"
              type="danger"
              size="small"
              >删除</el-button
            >
          </el-form-item>
        </div>

        <div class="add-image" v-if="imgList.length < 5 && types == 1">
          <span @click="addImage">增加轮播图</span>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelEdit">取消</el-button>
        <el-button type="primary" @click="submitadd">确认</el-button>
      </span>
    </el-dialog>
    <CheckPhoto ref="CheckPhoto" :imgId="imgId" :imgUrl="imgUrl"></CheckPhoto>
  </div>
</template>
<script>
import {
  homepageCreate,
  getHomelist,
  homeEdit,
  homedelete,
  homeEditStatus,
} from "@/api/data.js";
import CheckPhoto from "@/components/CheckPhoto.vue";
export default {
  components: { CheckPhoto },
  data() {
    return {
      page: 1,
      limit: 10,
      total: 0,
      pageId: "",
      status: "",
      title: "0",
      singleList: [{}],
      imgId: "",
      imgUrl: "",
      imgList: [{}],
      dialogPvVisible: false,
      multipleSelection: [],
      codes: "",
      titles: "",
      types: "",
      tableData: [],
    };
  },
  created() {},
  watch: {},
  mounted() {
    this.search();
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.search();
    },
    newAdd() {
      this.title = "0";
      this.dialogPvVisible = true;
      this.codes = "";
      this.titles = "";
      this.types = "";
      this.imgList = [{}];
      this.singleList = [{}];
    },
    cancelEdit() {
      this.dialogPvVisible = false;
      this.search();
    },
    detailEdit(item) {
      this.pageId = item.page_id;
      this.status = item.status;
      this.dialogPvVisible = true;
      this.title = "1";
      this.codes = item.code;
      this.titles = item.title;
      this.types = item.type;
      if (item.type == 1) {
        // 多图
        this.imgList = JSON.parse(JSON.stringify(item.content));
      } else if (item.type == 2) {
        // 单图
        this.singleList = JSON.parse(JSON.stringify(item.content));
      }
    },
    checkImg(item) {
      this.imgId = item.image;
      this.$refs.CheckPhoto.open();
    },
    submitadd() {
      let content = [];
      if (this.types == 1) {
        // 多图
        content = this.imgList.map(function (item, index, arr) {
          return {
            image: item.image,
            skip_url: item.skip_url,
          };
        });
        let empty = null
        content.forEach(value => {
          if (!value.image){
            empty = true
          }
        });
        if (empty) {
          this.$message.error('请设置图片');
          return
        }
      } else if (this.types == 2) {
        // 单图
        content = this.singleList.map(function (item, index, arr) {
          return {
            image: item.image,
            skip_url: item.skip_url,
            sort: item.sort,
            hot_activity: item.hot_activity,
            guidance_document: item.guidance_document,
            activity_document_one: item.activity_document_one,
            activity_document_two: item.activity_document_two,
            activity_people_number: item.activity_people_number,
          };
        });
        
        let empty = null
        let empty2 = null
        content.forEach(value => {
          if (!value.image){
            empty = true
          }
          if (!value.skip_url){
            empty2 = true
          }
        });
        if (empty) {
          this.$message.error('请设置图片');
          return
        } else if (empty2) {
          this.$message.error('请设置跳转链接');
          return
        }
      }
      if (this.title === "0") {
        // 新增
        homepageCreate({
          title: this.titles,
          code: this.codes,
          type: this.types,
          url_tag: "index", // 默认写死index
          status: 2, // 状态，1=>启用，2=>禁用
          content: content,
        }).then(({ data: res }) => {
          if (res.code === 200) {
            this.$message.success("添加成功");
            this.dialogPvVisible = false;
            this.titles = "";
            this.codes = "";
            this.types = "";
            this.search();
          } else {
            this.$message.error(res.message);
          }
        });
        this.titles = "";
        this.codes = "";
        this.types = "";
      } else if (this.title === "1") {
        // 编辑
        homeEdit({
          page_id: this.pageId,
          status: this.status, // 状态，1=>启用，2=>禁用
          title: this.titles,
          code: this.codes,
          type: this.types,
          url_tag: "index", // 默认写死index
          content: content,
        }).then(({ data: res }) => {
          if (res.code === 200) {
            this.$message.success("修改成功");
            this.dialogPvVisible = false;
            this.titles = "";
            this.codes = "";
            this.types = "";
            this.search();
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    editStatus(type, item) {
      homeEditStatus({
        page_id: item.page_id,
        status: type,
      }).then(({ data: res }) => {
        if (res.code === 200) {
          this.$message.success("状态修改成功");
          this.search();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    delMolude() {
      if (this.multipleSelection.length === 0) {
        this.$message.error("请选择");
        return;
      }
      this.$confirm("一旦删除数据，将不可恢复！", "警告", {
        distinguishCancelAndClose: true,
        confirmButtonText: "取消",
        cancelButtonText: "确认",
      })
        .then(() => {})
        .catch((action) => {
          if (action === "cancel") {
            let arr = [];
            arr = this.multipleSelection.map(function (item, index, arr) {
              return item.page_id;
            });
            homedelete({
              page_id: arr,
            }).then(({ data: res }) => {
              if (res.code === 200) {
                this.search();
              } else {
                this.$message.error(res.message);
              }
            });
          }
        });
    },
    search() {
      getHomelist({
        page: this.page,
        limit: 10,
      }).then(({ data: res }) => {
        if (res.code === 200) {
          this.tableData = res.data.data;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    delImg(index) {
      this.imgList.splice(index, 1);
    },
    addBanner(index) {
      // this.singleList.push({});
      this.singleList.splice(index + 1, 0, {});
    },
    delBanner(index) {
      this.singleList.splice(index, 1);
    },
    addImage() {
      this.imgList.push({
        img: "",
        link: "",
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delData(item) {
      this.$confirm("一旦删除数据，将不可恢复！", "警告", {
        distinguishCancelAndClose: true,
        confirmButtonText: "取消",
        cancelButtonText: "确认",
      })
        .then(() => {})
        .catch((action) => {
          if (action === "cancel") {
            // r0del({
            //   heat_r0_id: item.id,
            // }).then(({ data: res }) => {
            //   if (res.code === 200) {
            //     this.$message.success("删除成功");
            //   } else {
            //     this.$message.error(res.message);
            //   }
            // });
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.head-box {
  height: 40px;
  margin: 20px 0 10px 0px;
}
.add-image {
  text-align: center;
  color: #3ea8d6;
}
.demo-form-inline {
  margin-top: 22px;
  padding-left: 50px;
}
.input-box {
  width: 120px;
  margin-right: 10px;
}
::v-deep .el-input {
  width: 260px;
}
</style>
