<template>
  <div class="big-box">
    <el-dialog
      title="查看图片"
      :visible.sync="centerDialogVisible"
      width="800px"
      center
    >
      <img style="width: 100%" :src="img" alt="" />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="close">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getimgUrl } from "@/api/data.js";
export default {
  data() {
    return {
      centerDialogVisible: false,
      img: "",
      id: "",
      url: "",
    };
  },
  props: {
    imgUrl: "",
    imgId: "",
  },
  watch: {
    imgUrl: {
      handler: function (val) {
        this.url = val;
      },
      immediate: true,
      deep: true,
    },
    imgId: {
      handler: function (val) {
        this.id = val;
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  methods: {
    close() {
      this.centerDialogVisible = false;
    },
    open() {
      this.img = ''
      setTimeout(() => {
        if (!this.url && !this.id) {
          return;
        }
        if (this.url) {
          this.img = this.url;
          this.centerDialogVisible = true;
        } else if (this.id) {
          this.img = "";
          getimgUrl({
            media_id: this.id,
          }).then(({ data: res }) => {
            if (res.code === 200) {
              this.img = res.data.url;
              this.centerDialogVisible = true;
            } else {
              this.$message.error(res.message);
              this.centerDialogVisible = false;
            }
          });
        }
      }, 200);
    },
  },
};
</script>
<style lang="less" scoped>
</style>
